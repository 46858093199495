:root {
	--color-primary: #1E3979;
	--color-primary-light: #638DF8;
	--color-grey-light-1: #faf9f9;
	--color-grey-light-2: #f4f2f2;
	--color-grey-light-3: #f0eeee;
	--color-grey-light-4: #ccc;
	--color-grey-dark-1: #404040;
	--color-grey-dark-2: #777;
	--color-grey-dark-3: #999;
	--color-red: #FE5B76;
	--color-green: #3AFD01;
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
	--line: 1px solid var(--color-grey-light-2);
}
* {
	margin: 0 ;
	padding: 0 ;
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;
	font-size: 62.5%;
}
@media only screen and (max-width: 68.75em) {
	html {
		font-size: 50%;
	}
}
body {
	font-weight: 400;
	line-height: 1.6;
	color: var(--color-grey-dark-2);
	background-color: var(--color-grey-light-3);
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	font-family: 'Montserrat', sans-serif;
}
.loader {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-primary);
}
.loader i {
	-webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
	animation:spin 4s linear infinite;
	font-size: 4.5rem;
	color: #fff;
}
.input-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
}
.input-container input,
.input-container textarea,
.input-container select {
	padding: 1rem;
	margin-top: 1rem;
	background-color: rgba(255, 255, 255, .6);
	border: none;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
	outline: none;
	resize: none;
	width: 100%;
}
.input-container button {
	border: 1px solid var(--color-primary);
	padding: 1rem;
	color: var(--color-primary);
	font-size: 1.4rem;
	cursor: pointer;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: auto;
}
.input-container button i {
	margin-left: 1rem;
}
.input-container button:hover {
	background-color: var(--color-primary);
	color: #fff;
}
.input-container i.loading {
	-webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
	animation:spin 4s linear infinite;
	font-size: 2.5rem;
	color: var(--color-primary);
	margin: 0 auto;
}
table {
	width: 100%;
	border-collapse: collapse;
	font-size: 1.4rem;
}
table th {
	background-color: var(--color-primary);;
	padding: 1rem;
	text-transform: uppercase;
	color: #fff;
	font-weight: 600;
}
table td {
	color: var(--color-grey-dark-1);
	padding: 1rem;
}
table tr:nth-of-type(even) td {
	background-color: rgba(99, 141, 248, .3);
}
table tr.red td {
	background-color: rgba(165, 38, 49, .3);
}
table td a {
	color: var(--color-primary);
	text-decoration: none;
}
table td input,
table td select,
table td textarea {
	width: 100%;
	padding: .5rem;
	border: none;
	box-shadow: .5px .5px 1px rgba(0, 0, 0, 0.1);
	background-color: rgba(255,255,255,.3);
	outline: none;
	resize: none;
}
table td.center {
	text-align: center;
}
table td.center button {
	margin: 0 auto;
}
table td button {
	border: none;
	background-color: transparent;
	display: flex;
	cursor: pointer;
	color: var(--color-primary-dark);
	outline: none;
}
table th button {
	padding: 1rem;
	cursor: pointer;
	outline: none;
	background-color: var(--color-primary-light);
	border: none;
	color: #fff;
}

/* ANIMATION */
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

/* LOG-IN */
.log-in {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--color-primary);
	background-size: cover;
	background-position: center;
}
.log-in__container {
	display: flex;
	flex-direction: column;
	padding: 4.5rem;
	width: 100%;
	max-width: 40rem;
	border-radius: .5rem;
	background-color: rgba(255, 255, 255, .6);
}
.log-in__container .logo {
	margin: 0 auto;
}
.log-in__container .logo img {
	height: 10rem;;
}
.log-in__container form {
	margin-top: 2.5rem;
}
.log-in__container form button {
	margin-top: 2rem;
	background-color: var(--color-primary);
	color: #fff;
	text-transform: uppercase;
}
.log-in__container .link {
	margin: 2.5rem auto 0;
	font-size: 1.6rem;
	color: var(--color-primary-light);
	text-decoration: none;
	text-decoration: underline;
	font-weight: 500;
}
.log-in__container .btn {
	width: 100%;
	text-align: center;
	background-color: #fff;
	padding: 1rem;
	color: var(--color-grey-dark-1);
	text-decoration: none;
	font-size: 1.4rem;
	font-weight: 600;
	margin-top: 1.5rem;
	text-transform: uppercase;
}
.log-in__container .input-container {
	padding: 0;
}
.log-in__container input {
	background-color: #fff;
	border-radius: .5rem;
	padding: 1.5rem;
}

/* REGIST */
.regist {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: var(--color-primary);
	background-size: cover;
	background-position: center;
}
.regist__container {
	background-color: rgba(255, 255, 255, .6);
	width: 100%;
	max-width: 45rem;
	padding: 2.5rem;
	display: flex;
	flex-direction: column;
}
.regist__container .logo {
	margin: 0 auto;
}
.regist__container .logo img {
	height: 8rem;
}
.regist__container h1 {
	font-size: 2rem;
	color: var(--color-grey-dark-1);
	text-align: center;
	font-weight: 500;
	margin-top: 2.5rem;
}
.regist__container form {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
}
.regist__container form > button {
	margin: 2rem auto;
	padding: 1.5rem 2rem;
	font-size: 1.4rem;
	font-weight: 600;
	background-color: var(--color-primary);
	color: #fff;
	border: none;
	cursor: pointer;
	outline: none;
}

/* APP */
.app {
	display: flex;
	height: 100vh;
}
.app .sidenav {
	flex: 0 0 20%;
	background-image: linear-gradient(to bottom, var(--color-primary-light), var(--color-primary));
}
.app .sidenav .title {
	padding: 2rem;
}
.app .sidenav .title h3 {
	font-size: 1.6rem;
	padding: 1rem 0 2rem;
	border-bottom: 1px solid #fff;
	color: #fff;
	text-align: center;
	font-weight: 400;
	letter-spacing: 1px;
}
.app .sidenav ul {
	display: flex;
	flex-direction: column;
}
.app .sidenav ul a {
	padding: 2rem;
	display: flex;
	align-items: center;
	text-decoration: none;
	font-size: 1.4rem;
	font-weight: 400;
	color: #fff;
	position: relative;
}
.app .sidenav ul a i {
	margin-right: 1rem;
	position: relative;
}
.app .sidenav ul a span {
	position: relative;
}
.app .sidenav ul a::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 100%;
	background-color: #fff;
}
.app .sidenav ul a::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 100%;
	background-image: linear-gradient(to right, rgba(255,255,255,.3), rgba(255,255,255,0));
	transition: all .5s;
}
.app .sidenav ul a:hover::before,
.app .sidenav ul a.active::before {
	width: 2px;
}
.app .sidenav ul a:hover::after,
.app .sidenav ul a.active::after {
	width: 100%;
}
.app .sidenav ul li {
	padding: 2rem;
	display: flex;
	align-items: center;
	text-decoration: none;
	font-size: 1.4rem;
	font-weight: 400;
	color: #fff;
	position: relative;
	cursor: pointer;
}
.app .sidenav ul li i {
	margin-right: 1rem;
	position: relative;
	z-index: 9;
}
.app .sidenav ul li span {
	position: relative;
	z-index: 9;
}
.app .sidenav ul li::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 100%;
	background-color: var(--color-red);
}
.app .sidenav ul li::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 100%;
	background-image: linear-gradient(to right, rgba(254, 91, 118, .3), rgba(255,255,255,0));
	transition: all .5s;
}
.app .sidenav ul li:hover::before {
	width: 2px;
}
.app .sidenav ul li:hover::after {
	width: 100%;
}
.app__container {
	flex: 1;
	overflow-y: auto;
}

/* DASHBOARD */
.dashboard {
	padding: 2.5rem;
}
.dashboard ul {
	display: flex;
	list-style: none;
}
.dashboard ul li {
	padding: 4.5rem;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 1rem;
	font-size: 1.4rem;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
.dashboard ul li span {
	color: var(--color-grey-dark-3);
}
.dashboard ul li b {
	color: var(--color-grey-dark-1);
}

/* HOTELS */
.hotels {
	padding: 2.5rem;
	display: flex;
}
.hotels form {
	flex: 1;
	margin-right: 2.5rem;
}
.hotels__container {
	flex: 3;
}
.hotels__container ul {
	list-style: none;
	display: flex;
	flex-direction: column;
}
.hotels__container ul a {
	text-decoration: none;
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background-color: #fff;
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
.hotels__container ul a:hover {
	background-color: var(--color-primary-light);
	color: #fff;
	box-shadow: var(--shadow-light);
}
.hotels__container ul a i {
	margin-right: 1rem;
}
.hotels__container ul a span {
	text-transform: uppercase;
}

/* SINGLE-HOTEL */
.single-hotel {
	padding: 2.5rem 2.5rem 9rem;
	display: flex;
	flex-wrap: wrap;
}
.single-hotel .input-container {
	flex: 1;
}
.single-hotel > form {
	width: 100%;
	max-width: 40rem;
	padding: 2rem;
	background-color: #fff;
	margin-top: 2rem;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.single-hotel > form picture {
	display: flex;
	align-items: center;
	justify-content: center;
}
.single-hotel > form picture img {
	width: 100%;
}
.single-hotel section {
	flex: 0 0 100%;
	margin-top: 4.5rem;
	display: flex;
}
.single-hotel section form {
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-right: 2.5rem;
	justify-content: flex-start;
}
.single-hotel section form .input-container {
	flex: unset;
}
.single-hotel section form h3 {
	font-size: 2rem;
	color: var(--color-primary);
	border-bottom: 1px solid var(--color-primary);
}
.single-hotel section ul {
	flex: 2;
	list-style: none;
}
.single-hotel section ul li {
	display: flex;
	overflow: hidden;
	border-radius: 3rem;
	align-items: center;
	background-color: #fff;
	margin-bottom: 2rem;
}
.single-hotel section ul li button {
	cursor: pointer;
	background-color: var(--color-red);
	color: #fff;
	padding: .5rem;
	border: none;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1rem;
}
.single-hotel section ul li > i {
	margin-right: 1rem;
	color: var(--color-grey-dark-1);
}
.single-hotel section ul li > span {
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
}
.single-hotel section table {
	flex: 2;
}

/* ROOMS */
.rooms {
	padding: 2.5rem;
}

/* MESSAGES */
.messages {
	display: flex;
}
.messages ul {
	padding: 2rem;
	flex: 1;
}
.messages ul h2 {
	font-size: 2rem;
	text-align: center;
	border-bottom: 2px solid var(--color-primary);
	padding: 1rem;
	color: var(--color-primary);
	text-transform: uppercase;
}

/* SUSCRIPTIONS */
.suscriptions {
	padding: 2.5rem;
}

/* TRANSACTIONS */
.transactions {
	padding: 2.5rem;
}